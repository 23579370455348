import * as dompack from "dompack";
import * as forms from '@mod-publisher/js/forms';
import { TimeField } from '@mod-publisher/js/forms/fields/datetime';

// Load neutral styling (optional, but you'll need to supply your own styling for some of the fields below if you skip this)
import '@mod-publisher/js/forms/themes/neutral';

import './buttons.scss';
import './checkbox-radio.scss';
import './textual-input.scss';
import './datetime.scss';
import './formlayout.scss';
import './pulldown.scss';
//import './uploadfields.scss';
//import './multiselect.scss';
import "./dateinputgroup.scss";


// Enable forms and our builtin validation
forms.setup({ validate:true });

// Replaces date fields with a split version
// dompack.register(".wh-form__date", node => new DateField(node, { datepicker: false, resetcontrol: false}));
dompack.register(".wh-form__time", node => new TimeField(node, { resetcontrol: false}));
