import * as dompack from 'dompack';
import 'dompack/browserfix/reset.css';

import * as whintegration from '@mod-system/js/wh/integration';
import '@mod-system/js/wh/errorreporting'; //log JS errors to notice log

import "@typopro/web-dosis/TypoPRO-Dosis.css";

import './shared/forms';
import './shared/rtd/rtd';
import './almere18.scss';

import "./pages/tool";

import "./widgets/summary";


// open external links in new window - see https://code.webhare.com/publisher/utilities/linkhandler/
import { openLinksInNewWindow } from '@mod-publisher/js/linkhandler';



openLinksInNewWindow({ extensions:    [ 'pdf', 'rss' ]
                    //, internalhosts: internalhosts
                     });
