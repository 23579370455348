import "./tool-page.scss";
import "./tool-intro.scss";
import "./tool-tool.scss";
import "./tool-toolresults.scss";

import "./infobutton.scss";
import "./infobutton-tippy.scss";

//import * as dialogapi from 'dompack/api/dialog';
import * as dompack from "dompack";
import * as effecttool from "@mod-toolplatform/tools/effecttool";
import * as whintegration from "@mod-system/js/wh/integration";

import tippy from 'tippy.js';
//import tippy, {followCursor} from 'tippy.js/headless';
import 'tippy.js/dist/tippy.css';



// window.setInterval(function() {console.log(document.activeElement); }, 500);



let debugmode;
if (location.href.indexOf("debug") > -1)
  debugmode = true;


// Nodes
let toolnode;

const tellen = ["", "één", "twee", "drie", "vier", "vijf", "zes", "zeven", "acht", "negen", "tien", "elf", "twaalf", "dertien", "veertien", "vijftien", "zestien", "zeventien", "achtien", "negentien", "twintig"];

let prefilllink = "";


function initToolExtras(node)
{
  console.info("INIT 18inalmere tool extras");

  let trackerid = whintegration.config.dtapstage == "production" ? "G-6YZJX55Y8E" : "G-94T9D1YYD5";
  effecttool.setupGA(trackerid, "Almere18tool");


  let startbuttons = document.querySelectorAll(".tool__startbutton");
  for (let node of startbuttons)
    node.addEventListener("click", doStartTool);


  //alert("100vh" + document.body.height + ", real height " + window.innerHeight);

  window.addEventListener("resize", updateRealVH);
  updateRealVH();


  if (debugmode)
    document.documentElement.classList.add("debug-p3d");

  toolnode = node;

  resetProgressBar();

  // Listen for the tool to be finished. When it shows the resultpage we go to page 1b (our custom resultpage) and enable the button to page 2
  node.addEventListener("toolplatform:progress", onToolPageSwitch);
  node.addEventListener("toolplatform:resultspage", onToolResultsPage);


  let copylinkbutton = document.querySelector(".customtool__copyprefilllink");
  if (copylinkbutton)
    copylinkbutton.addEventListener("click", doCopyPrefillLinkToClipboard);

  let restartbutton = document.querySelector(".customtool__restart");
  if (restartbutton)
    restartbutton.addEventListener("click", doRestart);

  document.querySelector(".toolinfo__page2__footerlinks").addEventListener("click", doCheckForPageClick);

  document.querySelector(".customtool__infoscreen").addEventListener("click", doCheckForBacklink);

  moveInfoButtonsToQuestionHeader(); // handle the question info buttons
  // tippy('[data-tippy-content]'); // handle custom info buttons in the witty

  prefillAnswersFromURL();
}

function updateRealVH()
{
  document.documentElement.style.setProperty("--realvh", window.innerHeight + "px");
}

function doStartTool()
{
  toolnode.classList.add("showtool");
}

function doCheckForPageClick(evt)
{
  let pagename = evt.target.dataset.setpage;
  document.documentElement.setAttribute("data-custompage", pagename);

  let pages = document.querySelectorAll("[data-page]");
  for(let node of pages)
    node.classList[node.dataset.page == pagename ? "add" : "remove"]("activepage");
}

function doCheckForBacklink(evt)
{
  let backbutton = evt.target.closest(".customtool__infoscreen__backlink");
  if (backbutton)
  {
    document.documentElement.removeAttribute("data-custompage");

    let pages = document.querySelectorAll("[data-page]");
    for(let node of pages)
      node.classList.remove("activepage");
  }
}



function resetProgressBar()
{
  let questions = document.querySelectorAll(".effecttool__question").length;
  setProgress(1, questions);
}

function doRestart()
{
  /*
  NOTE: this version without reload doesn't work correctly yet.. After the first question it jumps to the results.

  window.__toolcontroller.goToQuestionById(window.__toolcontroller.getQuestionIdByNr(0));
  resetProgressBar();
  toolnode.classList.remove("showtool");
  */

  window.addEventListener("beforeunload", () => window.scrollTo(0,0));
  location.reload();
}



function generateURLFromAnswers(answers)
{
  // answers
  //   .uid
  //   .value[]
  let panswers = [];
  for (let answer of answers)
  {
    console.log("answer", answer);
    panswers.push(answer.uid + "=" + encodeURI( answer.value.join(",") ));
  }
  let query = panswers.join("&");

  console.log(query);

  let finalurl = location.origin + location.pathname + "?prefill&" + query;

  console.log(finalurl);

  return finalurl;
}


function doCopyPrefillLinkToClipboard()
{
  navigator.clipboard.writeText(prefilllink);
}



function prefillAnswersFromURL()
{
  console.info("prefillAnswersFromURL");

  let urlParams = new URLSearchParams(window.location.search);

  if (!urlParams.has("prefill"))
    return;

  for (const [key, detokenizedvalue] of urlParams.entries())
  {
    let values = detokenizedvalue.split(",");

    for (const value of values)
    {
        console.log(key, value);

        // For check name={guid}
        // For radiobutton name=answer-{guid}

        let node = document.querySelector(`[name^="${key}"][value="${value}"]`);
        if (node)
        {
          node.checked = true; // check the checkbox
          node.setAttribute("checked", true);
          console.log("SELECTED", node);
          continue;
        }

        node = document.querySelector(`[name^="answer-${key}"][value="${value}"]`);
        if (node)
        {
          node.checked = true;
          node.setAttribute("checked", true);
          console.log("SELECTED", node);
          continue;
        }

        //console.log("Node", node);
        console.log("Failed to find", key, "value", value);
    }
  }


  let breakin = 99;
  while(true) // do...while
  {
    let result = window.__toolcontroller.doNext();
    console.log(result);
    if (!result.success || result.page == "results")
      break;

    breakin--;
    if (breakin === 0)
    {
      console.error("FAIL");
      break;
    }
  }

  doStartTool();


  /*

  Selector to find the checkbuttons
  [name^="{questionguid}"][value="{answerguid}"]

  Checkbox
  <input type="checkbox" name="vg1RXa6bSAUVkstyaUlCAQ" value="Z-aWgErRYJi3JGZD3vwLvg" id="answer-Z-aWgErRYJi3JGZD3vwLvg" data-uid="Z-aWgErRYJi3JGZD3vwLvg" data-points="0" autocomplete="off">


  Selector to find the radiobuttons
  [name^="answer-{questionguid}"][value="{answerguid}"]

  Radiobutton
  <input type="radio" name="answer-SUY8GCyVcklX9shNbLojpg"
         value="gg4qgq2DLyGI5cJUYz0ufQ"
         id="answer-SUY8GCyVcklX9shNbLojpg-1" data-points="0" autocomplete="off">

  */
}



/////////////////////////////////////////////////////////////////////
//
//  Vragen pagina's


// Move the info in the DOM and attach Tippy so the info appears as tooltip
function moveInfoButtonsToQuestionHeader()
{
  let questionnodes = toolnode.querySelectorAll(".effecttool__question");

  for (let questionnode of questionnodes)
  {
    let moreinfo = questionnode.querySelector(".effecttool__moreinfo");
    if (!moreinfo)
      continue;

    let new_info = getInfoButton(); //moreinfo.innerHTML);

    // new_info.querySelector(".p3dtool__infobutton__popup").appendChild(moreinfo);
    tippy(new_info, { content: moreinfo.innerHTML, allowHTML: true });
    moreinfo.parentNode.removeChild(moreinfo); // remove the original moreinfo node


    let questiontext_node = questionnode.querySelector(".effectool__questiontext");
    let questiontext_h2 = questiontext_node.querySelector("h2");

    if (questiontext_h2)
      questiontext_h2.appendChild(new_info);
    else
      questiontext_node.appendChild(new_info);
  }
}

function getInfoButton()
{
  /*
  return <div class="p3dtool__infobutton">
           <div class="p3dtool__infobutton__button" tabindex="0"></div>
           <div class="p3dtool__infobutton__popup">
           </div>
         </div>;
  */
  return <div class="p3dtool__infobutton">
           <div class="p3dtool__infobutton__button" tabindex="0"></div>
         </div>;
}

function onToolPageSwitch(evt)
{
  /*
  NOTES:
  - at this point .effecttool__page--visible is still the page from before .nextPage() was called
  - window.__toolcontroller contains the ToolController instance (we assume to only have one tool on the page)
  */

  console.log("toolplatform:progress", evt.detail);

  setProgress(evt.detail.numquestions - evt.detail.questionsleft + 1, evt.detail.numquestions);

  let nextpage = evt.detail.nextpage;
  if (!nextpage.question) // not a question page (probably the resultpage)
    return;
}


function setProgress(current, total)
{
  // effecttool__pages
  let currentnode = document.querySelector(".customtool__progress");
  if (currentnode)
    currentnode.remove();

  if (current == total + 1)
  {
    // We are at the results page
    return;
  }

  console.info("setProgress", current, total);

  let container = document.createElement("div");
  container.classList.add("customtool__progress");

  for (let page = 1; page <= total; page++)
  {
    let item = document.createElement("div");
    item.className = page == current ? "customtool__progress__item customtool__progress__item--selected" : "customtool__progress__item"
    container.appendChild(item);
  }

  let pagescontainer = document.querySelector("#effecttool__pages");
  pagescontainer.insertBefore(container, pagescontainer.firstChild);

  console.info("pagescontainer", pagescontainer);
}


/////////////////////////////////////////////////////////////////////
//
//  Resultaten pagina

function onToolResultsPage(evt)
{
  console.group("onToolResultsPage");
  console.log("[p3dtool] Results page", evt.detail);

  //console.log("Answers", evt.detail.answers);

  let results = evt.detail;
  window.toolresult = results;

  // let results = getToolResults(); // gives back some more info than passed in evt.details (points and measuregroups) - NOT EXPOSED
  console.log("getToolResults", results);
  console.groupEnd();

  morphEffectsIntoAccordeon();

  makePartOfEffectsGroupTitleBold();

  updateEffectsCountText(evt);

  prefilllink = generateURLFromAnswers(evt.detail.answers);


  // Update mailto: URL in button
  let subject = "18 in Almere";
  let body = prefilllink;
  let anchorbutton = document.querySelector(".customtool__mailprefilllink");
  anchorbutton.href = `mailto:?subject=${encodeURIComponent(subject)}&body=${encodeURIComponent(body)}`;
}

function morphEffectsIntoAccordeon()
{
  /*
  Normal tool DOM:

  div.effecttool__group
  div.effecttool__group__header
    div.effecttool__group__title
  div.effecttool__effect
    div.effecttool__effect__description
      h2.heading2
      p.normal
      ul.unordered

  Converted tool DOM:

  div.effecttool__group
  div.effecttool__group__header
    div.effecttool__group__title
  details.effecttool__effect
    summary.effecttool__effect__customtitle
    div.effecttool__effect__description
      (first h2 removed)
      p.normal
      ul.onundered
  */
  // let groups = document.querySelectorAll(".effecttool__group");

  let effectnodes = document.querySelectorAll(".effecttool__effect:not([hidden])");

  for(let node of effectnodes)
  {
    let description = node.querySelector(".effecttool__effect__description")
    let icon = node.querySelector(".effecttool__effect__icon");

    let firsth2 = description.querySelector("h2");
    let title = firsth2.textContent;
    firsth2.remove();

    //console.log(node, title);

    let newelem =
      <details class="effecttool__effect effecttool__effect-details" data-variantkey={node.dataset.variantkey}>
        <summary class="effecttool__effect-details__header">
          <h2>
            <span class="h2icon"></span>
            <span class="h2text">{title}</span>
          </h2>
        </summary>
      </details>;

    if (icon)
    {
      let iconcontainer = newelem.querySelector(".effecttool__effect-details__header h2 .h2icon");
      iconcontainer.appendChild(icon);
    }

    newelem.appendChild(description);

    node.parentNode.replaceChild(newelem, node);
  }
}

function makePartOfEffectsGroupTitleBold()
{
  dompack.qSA(".effecttool__group__title").forEach(node =>
  {
    let parts = node.textContent.split("moet");
    if(parts.length == 2) //replace it!
    {
      node.textContent = "";
      node.append(parts[0],<strong>moet</strong>,parts[1]);
    }
  });
}

function updateEffectsCountText(evt)
{
  let effectscount = evt.detail.variants.length;
  // let effectstext = (effectscount > 1 ? "zijn " : "is ") + tellen[effectscount];
  let effectstext = tellen[effectscount];
  //document.querySelector(".effectscount").innerText = (effectscount > 1 ? "zijn " : "is ") + tellen[effectscount];

  dompack.qSA(".effecttool__results__header *").forEach(node =>
  {
    for(let subnode = node.firstChild; subnode; subnode = subnode.nextSibling)
      if(subnode.childNodes.length == 1 && subnode.firstChild.nodeType == 3) //contains only textnode
      {
        let parts = subnode.textContent.split("[aantal]");
        if(parts.length == 2) //replace it!
        {
          subnode.textContent = "";
          subnode.append(parts[0],<span class="effectscount">{effectstext}</span>,parts[1]);
        }
      }
  });
}



dompack.register(".effecttool--18inalmere", initToolExtras);
