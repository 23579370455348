import "./css/tools.scss";
import "./css/results.css";
import "./css/dialogs.scss";
import "./css/reminder.css";

import "./js/minfinwatbetekentditvoormij.es";

import "./components/icsFormatter.js";
import * as dompack from "dompack";
import { getDateOfBirth } from './js/toolcontroller.es';
export { getDateOfBirth } from './js/toolcontroller.es';
export { reportEvent, reportNewEvent, setupGA } from './js/statistics.es';




import * as dialogapi from 'dompack/api/dialog';
import * as dialog from 'dompack/components/dialog';


dialogapi.setupDialogs(options => dialog.createDialog('effecttool__dialog', options));//needed for recaptcha

window.getToolPrefillURL = function()
{
  let url = location.href.split('?')[0].split('#')[0] + "?";
  let dob = getDateOfBirth();
  if(dob)
    url += "&bd=" +dob;

  dompack.qSA(`input[name^="answer-"]`).forEach(answernode =>
  {
    if(answernode.checked)
      url += `&${encodeURIComponent(answernode.name)}=${encodeURIComponent(answernode.value)}`;
  });
  return url;
}


window._EffectToolAPI =
{
  getDateOfBirth
, getToolPrefillURL
}
