import * as pxl from "@mod-consilio/js/pxl";
import * as browser from 'dompack/extra/browser';

window._paq = window._paq || []; //we support PIWIK/Matomo natively, so prep it
window._ppas = window._ppas || []; //we support PIWIK pro natively, so prep it

// let ga_eventcategory;

export async function setupGA(trackerid, eventcategory, options)
{
  console.log("[ToolPlatform] Setting up GA \"%s\"", trackerid);

  options = { variantdimension: "" // deprecated
            , ...options
            };

  // ga_eventcategory = eventcategory;

  if (trackerid.substring(0,3) === "UA-")
  {
    console.warn("Universel Analytics isn't support anymore. (Google closed down that service)");
    return;
  }

  // Google Analytics 4 property
  if (options.variantdimension != "")
    console.error("variantdimension not supported for Google Analytics 4.");

  console.info(`Initializing Google Analytics 4 with property-ID ${trackerid}`);

  // https://support.google.com/analytics/answer/10271001
  let script = document.createElement("script");
  script.src = `https://www.googletagmanager.com/gtag/js?id=${trackerid}`;
  script.setAttribute("async", "");
  //document.body.firstElementChild;
  document.body.appendChild(script);

  window.dataLayer = window.dataLayer || [];
  function gtag(){dataLayer.push(arguments);}
  window.gtag = gtag;
  gtag('js', new Date());
  // gtag('config', "3171916937");
  return;
}

/** @short send event data to PIWIK/Matamo and old Google Analytics
*/
export function reportNewEvent(action, label, options)
{
  console.log('reportNewEvent', action, label, options);

  if(window._paq) //TODO check if matomo is actually enabled, _paq always exists
  {
    // Send an event to Matamo (ex-Piwik)
    if(options && options.sync && navigator.sendBeacon) //TODO only for this statement? but generally sync: is used when we're about to exit anyway
      window._paq.push(['alwaysUseSendBeacon']);
    window._paq.push(['trackEvent', 'Effecttool', action, label||'' ]);
  }

  if(window._ppas) { // Send an event to Piwik Pro
    if(options && options.sync && navigator.sendBeacon) //TODO only for this statement? but generally sync: is used when we're about to exit anyway
      window._ppas.push(['alwaysUseSendBeacon']);
    window._ppas.push(['trackEvent', 'Effecttool', action, label||'' ]);
  }
}

/*
Send event data to GA4 and PXL
*/
export function reportEvent(type, values, options)
{
  values = { ...values
//           , ds_theme:   formsettings.theme
//           , dn_version: formsettings.conceptmode ? 0 : formsettings.version
           // , ds_tool:    this.getToolId() //FIXME
           , ds_device:  browser.getTriplet()
           };

  // Push to Google Analytics 4
  if (window.gtag)
    window.gtag("event", type, values);

//FIXME conceptmode
  // if(!formsettings.conceptmode)
    pxl.sendPxlEvent("toolplatform:" + type.toLowerCase(), values, options);
  // else
    // console.log("Not sending pxl in concept mode:", {type, ...values});
}

export function setupPxl(formsettings)
{
  pxl.setPxlOptions({ recordurl: new URL("/.px/", formsettings.cdnroot || location.href).toString()
                    });
}
