import * as dompack from "dompack";

const months = [ "januari", "februari", "maart", "april", "mei", "juni", "juli", "augustus", "september", "oktober", "november", "december" ];
/** Formats a date in FormatDatetime's %d %B %Y' format
*/
function formatNLReadableDate(date)
{
  return date.getDate() + " " + months[date.getMonth()] + " " + date.getFullYear();
}

export class BaseQuestionType
{
  constructor(questionnode)
  {
    this.questionnode = questionnode;
  }
  checkError(value)
  {
    return "";
  }
  getValue()
  {
    throw new Error("Missing getValue implementation for question of type "  + this.questionnode.dataset.type)
  }
  getTitle()
  {
    return this.questionnode.dataset.questiontitle
  }
  getAnswerText()
  {
    return this.getValue();
  }
  getAnswerForStats()
  {
    return this.getValue();
  }
}

class DateType extends BaseQuestionType
{
  checkError(value)
  {
    if (!value || value == "")
      return "Geen datum ingevuld";

    var datevals = value.split('-');
    if (datevals.length < 3)
      return "Foutieve datum ingevuld";

    // create and validate the date
    try
    {
      var year =  parseInt(datevals[0]);
      var month = parseInt(datevals[1]) - 1;
      var day =   parseInt(datevals[2]);

      if (isNaN(day) || day < 1 || day > 31)
        return "Foutieve datum ingevuld";

      if (isNaN(month) || month < 0 || month > 11) /* month is null based */
        return "Foutieve datum ingevuld";

      if (isNaN(year) || year < 1900 || year > 2100)
        return "Foutieve datum ingevuld";

      var dateval = new Date(year, month, day);

      // check if the combination of day and month with the year
      // is a valid combination.
      if (isNaN(dateval.getTime()))
        return "Foutieve datum ingevuld";

      return "";
    }
    catch(err)
    {
      return "Foutieve datum ingevuld";
    }
  }

  getValue()
  {
    let dateday = this.questionnode.querySelector(".date-day").value;
    let datemonth = this.questionnode.querySelector(".date-month").value;
    let dateyear = this.questionnode.querySelector(".date-year").value;
    if(!dateday && !datemonth && !dateyear)
      return "";

    dateday = dateday.padStart(2, "0");
    datemonth = datemonth.padStart(2, "0");
    dateyear = dateyear.padStart(4, "0");

    return dateyear + "-" + datemonth + "-" + dateday;
  }

  getAnswerText()
  {
    const jsdate = new Date(this.getValue());
    return formatNLReadableDate(jsdate);
  }
}

class AmountType extends BaseQuestionType
{
  getValue()
  {
    return this.questionnode.querySelector("input").value;
  }

  checkError(value)
  {
    return value == "" || isNaN(value) ? "Controleer uw invoer" : "";
  }
}

class PulldownType extends BaseQuestionType
{
  getValue()
  {
    return this.questionnode.querySelector("select").value;
  }
  getAnswerText()
  {
    let opt = this.questionnode.querySelector("select").selectedOptions[0];
    return opt ? opt.textContent : "";
  }
  checkError(value)
  {
    if(!value)
      return "Je hebt geen keuze gemaakt.";
    return "";
  }
}
class CheckboxListType extends BaseQuestionType
{
  getValue()
  {
    return dompack.qSA(this.questionnode, "input:checked").map(_ => _.value);
  }
  checkError(value)
  {
    if(value.length == 0 && this.questionnode.dataset.required)
      return "Je hebt geen keuze gemaakt.";
    return "";
  }
  getAnswerText()
  {
    let answers = dompack.qSA(this.questionnode, "input:checked").map(opt => opt.closest(".effecttool__checkboxlist__answer").textContent);
    return answers.join(", ");
  }
  getAnswerPoints()
  {
    let answers = dompack.qSA(this.questionnode, "input:checked");

    let points = 0;
    for(let answer of answers)
      points += answer.hasAttribute('data-points') ? parseInt(answer.getAttribute('data-points')) : 0;

    return points;
  }
}
class RadioType extends BaseQuestionType
{
  getValue()
  {
    let opt = this.questionnode.querySelector("input:checked");
    return opt ? opt.value : "";
  }
  checkError(value)
  {
    if(!value.length)
      return "Je hebt geen keuze gemaakt.";
    return "";
  }
  getAnswerText()
  {
    let opt = this.questionnode.querySelector("input:checked");
    return opt ? opt.closest(".effecttool__radiolist__answer").textContent : "";
  }
  getAnswerPoints()
  {
    let opt = this.questionnode.querySelector("input:checked");
    return opt.hasAttribute('data-points') ? parseInt(opt.getAttribute('data-points')) : 0;
  }
}

let questionhandlers =
  { "date": DateType
  , "amount": AmountType
  , "pulldown": PulldownType
  , "checkboxlist": CheckboxListType
  , "radio": RadioType
  };

export function register(typename, classconstructor)
{
  questionhandlers[typename] = classconstructor;
}

export function getQuestionClass(questionnode)
{
  let type = questionnode.dataset.type;
  if(!questionhandlers[type])
    throw new Error(`Unrecognize question type '${type}'`);
  return new questionhandlers[type](questionnode);
}
